import React from 'react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import PropTypes from 'prop-types'

const Bodytext = (props) => {
  const { body, theme } = props

  return (
    <React.Fragment>
      <div className="bodytext">
        <MDXRenderer>{body}</MDXRenderer>
      </div>

      <style jsx>{`
        .bodytext {
          animation-name: bodytextEntry;
          animation-duration: ${theme.time.duration.long};
          :global(ol) {
            padding-left: 1.5em;
          }
          :global(h2),
          :global(h3) {
            margin: 1.5em 0 1em;
          }

          :global(h2) {
            line-height: ${theme.font.lineHeight.s};
            font-size: ${theme.font.size.m};
          }

          :global(h3) {
            font-size: ${theme.font.size.s};
            line-height: ${theme.font.lineHeight.m};
          }

          :global(p) {
            font-size: ${theme.font.size.s};
            line-height: ${theme.font.lineHeight.xxl};
            margin: 0 0 1em;
          }
          :global(p:first-child) {
            margin: 0 0 0.5em;
          }
          :global(ul) {
            list-style: circle;
            margin: 0 0 1.5em;
            padding: 0 0 0 1.5em;
          }
          :global(li) {
            margin: 1.5em 0;
            line-height: 1.8;
          }
          :global(a) {
            font-weight: ${theme.font.weight.bold};
            color: ${theme.color.brand.primary};
            text-decoration: underline;
          }
          :global(a.gatsby-resp-image-link) {
            border: 0;
            display: block;
            margin: 2.5em 0;
            border-radius: ${theme.size.radius.default};
            overflow: hidden;
            border: 1px solid ${theme.line.color};
          }
          :global(code.language-text) {
            // background: ${theme.color.neutral.gray.c};
            // background: pink;
            background: transparent;
            color: rgb(213, 174, 103) !important;
            font-weight: 500;
            text-shadow: none;
            color: inherit;
            padding: 0.1em 0.3em 0.2em;
            border-radius: 0.1em;
          }
          :global(table) {
            border-collapse: separate;
            margin: 1em 0;
            border-top: 1px solid #ebeef5;
            border-left: 1px solid #ebeef5;

          }
          :global(tr) {
            border-collapse: separate;
          }
          :global(td),:global(th) {
            padding: 0.5em;
            border-right: 1px solid #ebeef5;
            border-bottom: 1px solid #ebeef5;
          }
          :global(blockquote) {
            display: block;
            padding: 5px 15px;
            font-size: 12px;
            line-height: 1.3;
            // background: #f6ffed;
            background: #e6f7ff;

            position: relative;
            &::before {
              position: absolute;
              left: 0;
              top: 0;
              bottom: 0;
              width: 5px;
              display: block;
              content: ' ';
              // background: #b7eb8f;
              background: #91d5ff;

            }
            :global(p) {
              margin: 0 !important;
            }
          }
        }

        @keyframes bodytextEntry {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </React.Fragment>
  )
}

Bodytext.propTypes = {
  body: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
}

export default Bodytext
